<template>
  <div class="appendix">
    <div v-html="content" class="content" />
    <div style="width: 100%;text-align: center;">
      <van-button type="primary" class="btn" @click="before" :disabled="isDisabled">
        我已认真阅读
      </van-button>
    </div>
  </div>
</template>

<script>
import { Button } from "vant";
import { useStore } from "vuex";
import { onMounted, ref } from "vue";
import httpApi from "../../utils/httpApi";

const content = `
ISDA®

International Swaps and Derivatives Association, Inc.

SCHEDULE

to the

2002 Master Agreement

dated as of [                                          ]

between


[BEEVEST WEALTH MANAGEMENT LIMITED (蜂投财富管理有限公司)]

(“Party A”)

[Established as a private company limited by shares with company number 1988930]


[ (INSERT FULL NAME OF THE COUNTERPARTY AS SHOWN IN THE PASSPORT OR ID)]

 (“Party B”)


Part 1. Termination Provisions

(a)“Specified Entity” means:

in relation to Party A, for the purpose of:

Section 5(a)(v),      none;

Section 5(a)(vi),      none;

Section 5(a)(vii),      none; and

Section 5(b)(v),      none;

and, in relation to Party B, for the purpose of:

Section 5(a)(v),      any of its Affiliates;

Section 5(a)(vi),      any of its Affiliates;

Section 5(a)(vii),      any of its Affiliates; and

Section 5(b)(v),      any of its Affiliates.

(b)“Specified Transaction” will have the meaning specified in Section 14 of this Agreement.

(c)The “Cross-Default” provisions of Section 5(a)(vi) will not apply to Party A but will apply to Party B.

“Specified Indebtedness” will have the meaning specified in Section 14 of this Agreement.

“Threshold Amount” means,

(i)     with respect to Party A, zero; and

(ii)     with respect to Party B, [                                   (insert currency and amount)] (including the [insert currency] equivalent of obligations stated in any other currency or currency unit).[     To be reviewed on a case-by-case basis.

     Alternative formulations for the thresholds are possible. A common alternative is a percentage amount based on shareholder’s equity as follows:

     ““Threshold Amount” means, with respect to Party B, an amount equal to [3] per cent. of such party’s shareholders’ equity as specified from time to time in the most recently published annual report containing audited financial statements for the most recently audited financial year.”.

]

(d)The “Credit Event Upon Merger” provisions of Section 5(b)(v) of this Agreement will not apply to Party A and will apply to Party B.

(e)The “Automatic Early Termination” provisions of Section 6(a) of this Agreement will not apply to Party A and will [not][     It is important for Beevest to check whether Automatic Early Termination should apply by checking the ISDA commissioned netting opinion for the jurisdiction of incorporation of Party B to determine if close-out netting works in a particular jurisdiction and in respect of a bank. Where Automatic Early Termination is not specifically recommended by the relevant netting opinion, usual practice is for it to be disapplied in relation to both parties. Automatic Early Termination is not required for Hong Kong incorporated counterparties. Jurisdictions where it is generally considered advisable to apply Automatic Early Termination includes Mainland China, Belgium, Germany, Japan, Korea, the Netherlands, the Netherlands Antilles, Norway, South Africa and Switzerland. This list is not exhaustive and is not a substitute for checking the relevant netting opinion. ] apply to Party B.

(f)“Termination Currency” means [Hong Kong Dollars].

(g)     “Additional Termination Event” will apply to Party B. For the purpose of Section 5(b)(vi) of this Agreement, each of the following events shall constitute an Additional Termination Event in respect of which Party B shall be the sole Affected Party and all outstanding Transactions shall be Affected Transactions:

(i)Criminal conviction. Party B is convicted of any criminal offence in any jurisdiction whatsoever (excluding road traffic offences).

(ii)Material Adverse Change. Information is received by Party A either indicating:

(a)a material adverse change in the assets or the financial or other conditions of Party B or any Credit Support Provider of Party B, or

(b)that any other event has occurred or may occur which in the sole discretion of Party A, acting in good faith and in a commercially reasonable manner, impairs the ability of Party B or any Credit Support Provider of Party B to perform any of their respective obligations under this Agreement or any Credit Support Document.

(iii)Inadequate Assurance. Party B fails to provide adequate assurance of its ability to perform all of its outstanding obligations hereunder to Party A within [1] Local Business Day after a request is made by Party A when Party A has reasonable grounds for insecurity.

(iv)Death or Incapacity. Party B or any Credit Support Provider of Party B who is a natural person dies, or in the reasonable judgement of Party A, Party B becomes incapable in law of managing his affairs in any Relevant Jurisdiction (whether by reason of mental incapacity or any other reason whatsoever).

(v)Transfer of assets. Party A determines in its sole and absolute discretion acting in good faith and in a commercially reasonable manner that the credit-worthiness of Party B becomes materially weaker as a result of Party B transferring or being required to transfer a substantial part of his estate to a third party in return for no consideration or consideration of less value than the assets transferred.

(vi)[Insert any other applicable Additional Termination Event]


Part 2. Tax Representations

(a)Payer Representations: For the purpose of Section 3(e) of this Agreement, Party A and Party B each make the following representation:

[It is not required by any applicable law, as modified by the practice of any relevant governmental revenue authority, of any Relevant Jurisdiction to make any deduction or withholding for or on account of any Tax from any payment (other than interest under Section 9(h) of this Agreement) to be made by it to the other party under this Agreement. In making this representation, it may rely on (i) the accuracy of any representations made by the other party pursuant to Section 3(f) of this Agreement, (ii) the satisfaction of the agreement contained in Section 4(a)(i) or 4(a)(iii) of this Agreement and the accuracy and effectiveness of any document provided by the other party pursuant to Section 4(a)(i) or 4(a)(iii) of this Agreement and (iii) the satisfaction of the agreement of the other party contained in Section 4(d) of this Agreement, except that it will not be a breach of this representation where reliance is placed on clause (ii) above and the other party does not deliver a form or document under Section 4(a)(iii) of this Agreement by reason of material prejudice to its legal or commercial position.] [BVS tax department to consider whether these reps need to be adjusted based on the status of the relevant counterparty. No change needs to be made if Party B is a Hong Kong incorporated entity]

(b)Payee Representations: For the purpose of Section 3(f) of this Agreement, Party A and Party B will not make any payee tax representation.



Part 3. Documents to be delivered

For the purpose of Sections 4(a)(i) and 4(a)(ii) of this Agreement, each party agrees to deliver the following documents, as applicable:

(a)     Tax forms, documents or certificates to be delivered are: none. [BVS tax department to confirm if tax documents are required based on the status of the relevant counterparty. No such tax documents are needed if Party B is a Hong Kong incorporated entity]



(b)     Other documents to be delivered are:


Party Required to deliver Document :

Form/Document/Certificate :

Date by which to be Delivered :

Covered by Section 3(d) Representation :


Party Required to deliver Document : Party B

Form/Document/Certificate : Evidence reasonably satisfactory to Party A of: (i) the capacity and authority of Party B and any Credit Support Provider of Party B to enter into the Agreement, any Credit Support Document and any Transactions and (ii) the authority of the individual signing the Agreement, the relevant Credit Support Document and/or the relevant Confirmation on behalf of such party to execute the same and (iii) the genuine signature of each such individual.

Date by which to be Delivered : Promptly upon execution of this Agreement.

Covered by Section 3(d) Representation : Yes


Party Required to deliver Document : Party B

Form/Document/Certificate : A copy of identity card or passport of Party B or the Credit Support Provider of Party B.

Date by which to be Delivered : Promptly upon execution of this Agreement.

Covered by Section 3(d) Representation : Yes


Party Required to deliver Document : Party B

Form/Document/Certificate : Any Credit Support Document identified in Part 4(f), together with evidence of incumbency in relation to the person who has executed the Credit Support Document. [     Include if a Credit Support Document and Credit Support Provider will be designated for Party B]

Date by which to be Delivered : Date by which to be Delivered : Promptly upon execution of this Agreement.

Covered by Section 3(d) Representation : Yes


Party Required to deliver Document : Party B

Form/Document/Certificate : Evidence of the appointment of the Process Agent of Party B and acceptance of such appointment by the Process Agent. [Include if Party B does not have a permanent address in Hong Kong. ]

Date by which to be Delivered : Promptly upon (i) execution of this Agreement and (ii) the appointment of a replacement Process Agent pursuant to Section 13(c) of this Agreement.

Covered by Section 3(d) Representation : No


Party Required to deliver Document : Party B

Form/Document/Certificate : Any other documents as required by Party A from time to time.

Date by which to be Delivered : Upon request by Party A.

Covered by Section 3(d) Representation : Yes



Part 4. Miscellaneous

(a)Addresses for Notices: For the purpose of Section 12(a) of this Agreement:

Address for notices or communications to Party A: Unit 3905, 39/F, The Center, 99 Queen’s Road Central, Central, HONG KONG


BEEVEST WEALTH MANAGEMENT LIMITED (蜂投财富管理有限公司)

Attention: customer service department 客服部

Email:          customer-service@beevestwm.com

Facsimile No.:      Nil

Telephone No.: [+852 3621 0208]

Address：

Address for notices or communications to Party B:

[                                                            (Name of counterparty)]

Attention:

Email:

Facsimile No.:

Telephone No.:

Process Agent: For the purpose of Section 13(c) of this Agreement:

Party A appoints as its Process Agent: Not applicable.

Party B appoints as its Process Agent: Not applicable.



(b)Offices: The provisions of Section 10(a) will apply to this Agreement.

(c)Multibranch Party: For the purpose of Section 10(b) of this Agreement, Party A and Party B each is not a Multibranch Party.

(d)Calculation Agent: The Calculation Agent is Party A.

(e)Credit Support Document: Details of any Credit Support Document:

[                                                        ][     To include if parties will provide any Credit Support Document such as guarantee letter or security agreement. Kindly note that the English law CSA is not a Credit Support Document and does not need to be specified as such here. ]

(f)Credit Support Provider:

“Credit Support Provider” means, in relation to Party A: Not applicable

“Credit Support Provider” means, in relation to Party B: [Not applicable]/[Insert details of guarantor/pledgor]

(g)Governing Law and Jurisdiction:

(i)This Agreement will be governed by and construed in accordance with Hong Kong law.

(ii)Section 13(b) of the Agreement is hereby deleted in its entirety and replaced with the following:

(b) Jurisdiction. With respect to any dispute, claim, difference or controversy arising out of, relating to or having any connection with this Agreement, including any dispute as to its existence, validity, interpretation, performance, breach or termination or the consequences of its nullity and any dispute relating to any non-contractual obligations arising out of or in connection with it (“Proceedings”), each party irrevocably:

(i)submits to the exclusive jurisdiction of the Hong Kong courts; and

(ii)waives any objection which it may have at any time to the laying of venue of any Proceedings brought in any such court, waives any claim that such Proceedings have been brought in an inconvenient forum and further waives the right to object, with respect to such Proceedings, that such court does not have any jurisdiction over such party.”

(h)Netting of Payments: “Multiple Transaction Payment Netting” will apply for the purpose of Section 2(c) of this Agreement.

(i)“Affiliate” will have the meaning specified in Section 14 of this Agreement.

(j)Absence of Litigation.  For the purpose of Section 3(c):

“Specified Entity” means, in relation to Party A: None.

“Specified Entity” means, in relation to Party B: Affiliates.

(k)No-Agency: The provisions of Section 3(g) will apply to this Agreement.

(l)Additional Representations: will apply. For the purpose of Section 3 of this Agreement, each of the following will constitute an Additional Representation:

(i)Relationship Between Parties. Each party will be deemed to represent to the other party on the date on which it enters into a Transaction that (absent a written agreement between the parties that expressly imposes affirmative obligations to the contrary for that Transaction):

(1)     Non-Reliance. It is acting for its own account, and it has made its own independent decisions to enter into that Transaction and as to whether that Transaction is appropriate or proper for it based upon its own judgment and upon advice from such advisors as it has deemed necessary. It is not relying on any communication (written or oral) of the other party as investment advice or as a recommendation to enter into that Transaction; it being understood that information and explanations related to the terms and conditions of a Transaction will not be considered investment advice or a recommendation to enter into that Transaction. No communication (written or oral) received from the other party will be deemed to be an assurance or guarantee as to the expected results of that Transaction.

(2)     Assessment and Understanding. It is capable of assessing the merits of and understanding (on its own behalf or through independent professional advice), and understands and accepts, the terms, conditions and risks of that Transaction. It is also capable of assuming, and assumes, the risks of that Transaction.

(3)     Status of Parties. The other party is not acting as a fiduciary for or an adviser to it in respect of that Transaction.

(4)     Open Market Transactions. It understands and acknowledges that the other party (either directly or through its Affiliates), either in connection with entering into a Transaction or from time to time thereafter, engage in open market transactions that are designed to hedge or reduce the risks incurred by it in connection with such Transaction and that the effect of such open market transactions may be to affect or reduce the value of such Transaction.

(ii)No Required Approval. Party B further represents for the purposes of Section 3 of this Agreement that no approval is required from any regulator or relevant authority to enter into this Agreement and any Transactions governed hereby in compliance with all relevant legislation, or that if such approval is required, that it is duly licensed by, or has the approval from, the any regulator or relevant authority to do so, which approval is in full force and effect.

(m)Recording of Conversations. Each party (i) consents to the recording of the telephone conversations between trading, marketing and other relevant personnel of the parties and their Affiliates in connection with this Agreement or any potential Transaction, (ii) agrees to obtain any necessary consent of, and give any necessary notice of such recording to, such personnel and (iii) agrees, to the extent permitted by applicable law, that any such recording may be submitted in evidence to any court or in any Proceedings with respect to this Agreement or any Transaction.



Part 5. Other Provisions

(a)Change of Account. Section 2(b) of this Agreement is hereby amended by the addition of the following after the word “delivery” in the first line thereof “to another account in the same legal and tax jurisdiction as the original account”.

(b)Scope of Agreement. Notwithstanding anything contained in this Agreement to the contrary, any transaction (other than a repurchase transaction, reverse repurchase transaction, buy/sell-back transaction or securities lending transaction) which may otherwise constitute a "Specified Transaction" (without regard to the phrase “which is not a Transaction under this Agreement but” in the definition of “Specified Transaction”) for purposes of this Agreement which has been or will be entered into between the parties shall constitute a "Transaction" which is subject to, governed by, and construed in accordance with the terms of this Agreement, unless any Confirmation with respect to a Transaction entered into after the execution of this Agreement expressly provides otherwise.

(c)Consent to Disclosure. Without prejudice to the generality of any applicable law and notwithstanding anything to the contrary in any non-disclosure, confidentiality or other agreement between the parties, each of Party A and Party B expressly consents to the disclosure of information (including without limitation, its name and information relating to the Transaction):

(i)to the extent required or permitted by any applicable law, rule or regulation which mandates reporting and/or retention of such information in accordance with which the other party or its head office, branches or Affiliates (as applicable) are required or accustomed to act (“Reporting Requirements”); or

(ii)to and between the other party’s head office, branches or Affiliates, or any persons or entities who provide services to such other party or its head office, branches or Affiliates (as applicable), in each case, in connection with such Reporting Requirements.

(d)Fully Paid Transactions. The condition precedent in Section 2(a)(iii)(1) of this Agreement does not apply to a payment and delivery owing by a party if the other party shall have satisfied in full all its payment or delivery obligations under Section 2(a)(i) of this Agreement and shall at the relevant time have no future payment or delivery obligations, whether absolute or contingent, under Section 2(a)(i) of this Agreement.

(e)Transfers. Section 7 of this Agreement is hereby amended by the insertion, in the second line after the words “by way of security”, of the words “, declaration of trust”.

(f)Severability. If any one or more of the provisions contained in this Agreement should be held invalid, illegal or unenforceable in any respect, the validity, legality and enforceability of the remaining provisions contained herein shall not in any way be affected or impaired thereby. The parties shall endeavour, in good faith negotiations, to replace the invalid, illegal or unenforceable provision with valid, legal or enforceable provisions, the economic effect of which comes as close as reasonably possible to that of the invalid, illegal or unenforceable provisions, provided that this severability provision shall not affect the single agreement provision of Section 1(c) of this Agreement.

(g)Contracts (Rights of Third Parties) Ordinance. A person who is not a Party has no right under the Contracts (Rights of Third Parties) Ordinance (Cap. 623) to enforce or to enjoy the benefit of any term of this Agreement.

(h)Incorporation of 2002 Master Agreement Protocol. The parties agree that the definitions and provisions contained in Annexes 1 to 18 and Section 6 of the 2002 Master Agreement Protocol published on July 15, 2003 by the International Swaps and Derivatives Association, Inc. (the “2002 Master Agreement Protocol”) are incorporated into and apply to this Agreement and any Transaction hereunder, as applicable. References in those definitions and provisions to any ‘ISDA Master Agreement’ will be deemed to be references to this Agreement. For greater certainty, if there is any inconsistency between this provision and the provisions in a Confirmation of a Transaction, this provision shall prevail unless such Confirmation expressly overrides the provisions of the relevant annex to the 2002 Master Agreement Protocol.

(i)Withholding Tax imposed on payments to non-U.S. Counterparties under the United States Foreign Account Tax Compliance Act.

“Tax” as used in Part 2(a) of this Schedule (Payer Representations) and “Indemnifiable Tax” as defined in Section 14 of this Agreement shall not include any U.S. federal withholding tax imposed or collected pursuant to Sections 1471 through 1474 of the U.S. Internal Revenue Code of 1986, as amended (the “Code”), any current or future regulations or official interpretations thereof, any agreement entered into pursuant to Section 1471(b) of the Code, or any fiscal or regulatory legislation, rules or practices adopted pursuant to any intergovernmental agreement entered into in connection with the implementation of such Sections of the Code (a "FATCA Withholding Tax"). For the avoidance of doubt, a FATCA Withholding Tax is a Tax the deduction or withholding of which is required by applicable law for the purposes of Section 2(d) of this Agreement.

(j)Additional Representations.

(i).For the purpose of Section 3(a)(i) of this Agreement, Party A makes the representation as stated and Party B makes the following representation:

“Status. Party B is not a minor and has full legal capacity and authority to execute and deliver, and to perform Party B’s obligations under this Agreement and (if applicable) any Credit Support Document.”

(ii).For the purpose of Section 3(a)(iii) of this Agreement, the following words are deleted with respect to Party B: “any provision of its constitutional documents,”.

(k)Natural Person. The provisions of this Agreement that apply or refer to Party B as a legal person or imply that Party B is such a legal person, are to be construed, where applicable, as applying or referring to Party B as a natural person, making any necessary amendments to these provisions to give them their intended effect. Without limitation to the generality of the above, references to "it" or "its" are to be construed, in relation to Party B, as references to "he/she", "his/her" or "him/her" as appropriate.

(l)Bankruptcy/Merger without Assumption/Tax Event Upon Merger.

(i).Section 5(a)(vii) is amended with respect to Party B, (but for the avoidance of doubt not any Credit Support Provider of Party B unless such Credit Support Provider is a nature person) so that:

i.Section 5(a)(vii)(1) will not apply to Party B;

ii.the words “its winding up or liquidation” when they first appear in Section 5(a)(vii)(4) are replaced with the words “a declaration of bankruptcy with respect to him or her” and the words “or the making of an order for its winding-up or liquidation” in Section 5(a)(vii)(4)(B) are deleted; and

iii.Section 5(a)(vii)(5) is amended to read as follows:

“Party B declares himself or herself bankrupt”.

(ii)Section 5(a)(viii) and Section 5(b)(iv) will not apply to Party B, but will apply to any Credit Support Provider of Party B (other than a Credit Support Provider which is a natural person).

(m)Relevant Jurisdiction. The definition of “Relevant Jurisdiction” in Section 14 is amended to read as follows:

(i)references to “a party” and “the party” shall be deleted in their entirety and replaced with “Party A”; and

(ii)the following sentence be added to the end of the definition, after “such payment is made”:

“, and with respect to Party B, any jurisdiction (a) in which Party B is resident, domiciled and of which Party B is a citizen (b) where Party B or any agent through which Party B is acting for the purposes of this Agreement is located, (c) in which Party B executes this Agreement and (d) in relation to any payment, from or through which such payment is made”.


Please confirm your agreement to the terms of the foregoing Schedule by signing below.


[BEEVEST WEALTH MANAGEMENT LIMITED (蜂投财富管理有限公司)]

By:  ____________________________
Name: ___________________________
Title: ___________________________
Date:  ___________________________


 (INSERT FULL NAME OF THE COUNTERPARTY AS SHOWN IN THE PASSPORT OR ID)]
By:  ____________________________
Name: ___________________________
Date: ___________________________

`.replace(/\r?\n/g, "<br />");
export default {
  name: "baal",
  components: {
    [Button.name]: Button
  },
  data() {
    return {
      content
    };
  },
  methods: {
    async before() {
      const data = JSON.parse(JSON.stringify(this.submitForm));
      const res = await this.$api.postAgreementState({
        ...data,
        bwm_isda_schedule: "是"
      });
      if (res) {
        this.$router.back();
      }
    }
  },
  setup() {
    const isDisabled = ref(true);
    const submitForm = ref({});
    onMounted(async () => {
      const store = useStore();
      store.commit("setTitleValue", "协议详情");
      store.commit("setStepsShow", false);

      const result = await httpApi.getAgreementState();
      const { data } = result;
      isDisabled.value = data.bwm_isda_schedule === "是";
      submitForm.value = data;
      httpApi.formLog(submitForm.value, isDisabled);
    });
    return {
      isDisabled,
      submitForm
    };
  }
};
</script>

<style lang="less">
.appendix {
  margin-top: @space-2;
  text-align: left;

  .content {
    padding: @space-2;
    background: @contentColor;
    font-size: @font-size-2;
  }
  .btn {
    width: 80%;
    margin: @space-0 auto;
    margin-bottom: 0;
  }
}
</style>
